.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  // padding: 65px 0 60px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }
}
