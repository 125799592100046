.items {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.image {
  width: 200px;
  height: 200px;
}
