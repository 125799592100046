.info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  overflow-y: auto;
  // max-height: 90vh;
  min-width: auto;
  width: 100%;
  max-width: 435px;
  flex-shrink: 1;
  height: 100%;
  max-height: calc(100vh - 134px);

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_blocked {
    overflow-y: hidden;
  }

  @media screen and (min-width: 420px) {
    min-width: 360px;
  }

  @media screen and (min-width: 800px) {
    width: auto;
  }
}

.body {
  margin: 0;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 0;
  margin: 17px 23px 0;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.text {
  color: #363942;
  font-size: 13px;
  font-family: Roboto;
  line-height: 19px;
}

.filters {
  width: 29px;
  height: 29px;
  background: url(../../../assets/filters_square.svg) center no-repeat;
  display: block;
}

.sorting {
  width: 35px;
  height: 23px;
  background: url(../../../assets/logo_sort.svg) center no-repeat;
  display: block;
}
