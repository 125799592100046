.modal {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 10px;
  border-radius: 8px 8px 0;
  background: #fff;
  box-shadow:
    0 5px 24px 0 rgba(96, 101, 116, 31%),
    0 1px 4px 0 rgba(76, 91, 131, 24%);
  backdrop-filter: blur(3px);
  width: 300px;
  height: 60px;
  z-index: 100;
  justify-content: center;
  align-items: center;
  gap: 10px;

  &_open {
    position: fixed;
    display: flex;
    animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY((+1000px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.submitButton {
  color: #363942;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 143.75% */
  letter-spacing: 0.38px;
  border: none;
  text-align: left;
}

.closeButton {
  width: 20px;
  height: 20px;
  background: url(../../../assets/close.svg) center no-repeat;
  background-size: 20px;
  border: none;
}
