.page {
  display: flex;
  flex-direction: column;
  // background: #ffffff;
  // min-height: 100vh;
  position: relative;
  // padding: 84px 0 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  @media screen and (min-width: 1000px) {
    display: grid;
    grid-template-columns: 216px 1fr;
    padding: 0;
  }
}
