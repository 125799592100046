.dialog {
  display: none;
  position: relative;
  inset: 0;
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 50%);
  z-index: 100;
  justify-content: center;
  align-items: center;

  &_open {
    position: fixed;
    display: flex;
    animation: appear 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.body {
  border-radius: 14px;
  background: #fcfcfc;
  backdrop-filter: blur(11px);
  width: 270px;
  min-height: 123px;
  animation: show 250ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY((-100px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.items {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.heading {
  color: #363942;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 143.75% */
  letter-spacing: 0.38px;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  border-top: 1px solid rgba(60, 60, 67, 36%);

  & .button:nth-child(2) {
    border-left: 1px solid rgba(60, 60, 67, 36%);
  }
}

.button {
  color: #007aff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.41px;
  padding: 11px 0;
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}
